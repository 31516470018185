@import '../../../styles/index.scss';

div .button {
  border-radius: 6px;
  height: 30px;
  width: 90px;
  padding: 6.5px 20px;
  text-transform: unset;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  font-family: var(--Inter-font);

  &Outlined {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background-color: nonetransparent;
  }
  &Contained {
    border: 1px solid rgba(255, 255, 255, 0);
    background-color: var(--primary-color);
    color: var(--neutral-white);
  }

  &:hover,
  :active {
    box-shadow: none;
  }
}
