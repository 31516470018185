@import '../../styles/index.scss';

div .label {
  font-size: 16px;
  font-weight: 500;
  color: var(--neutral-gray-dark);
  margin-bottom: 4px;
  white-space: wrap;
  font-family: var(--Inter-font);
  line-height: 27.2px;
}

div .contentEditor {
  height: 517px !important;
  padding: 5px 0 0 0;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--Inter-font);
  line-height: 1.7;

  & > div {
    height: fit-content;
  }

  & > div > div > div > div > div > div {
    margin: 0;
  }
}

div .contentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 22px;
  border: 1px solid var(--border-color);
  color: var(--neutral-black);
  background-color: var(--neutral-white);
  border-radius: 10px;
  box-sizing: border-box !important;
  left: 0;
}

div .post {
  &Image {
    @include aspect-ratio(var(--aspect-of-crop-image-collection));
    display: block;

    border-radius: 8px;
    width: 288px;

    object-fit: cover;
    overflow: hidden;
  }
  &ImageDetailsView {
    @include aspect-ratio(var(--aspect-of-crop-image-page-details));
    display: block;
    border-radius: 8px;
    width: 400px;
    object-fit: cover;
    overflow: hidden;
  }

  &LabelContainer {
    position: absolute;
    bottom: 9px;
    left: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 19px;
    padding: 3px 12px;
    border-radius: 100px;
    width: fit-content;
  }
  &LabelText {
    font-family: var(--Inter-font);
    color: var(--neutral-white);
    font-size: 9px;
    font-weight: 500;
  }
}

div .content {
  color: #243757;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--Inter-font);
}

div .imagesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

div .imageViewDesc {
  margin-bottom: 10px;

  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  font-family: var(--Inter-font);

  color: var(--neutral-black);
}
