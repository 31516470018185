@import '../../styles/index.scss';

div .arrow {
  &Button {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: auto 12px auto 0;
  }

  &Icon {
    width: 16px;
    height: 16px;
    fill: var(--neutral-black);
  }
}
