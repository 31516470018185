@mixin mq($type) {
  $mobile: 480px;
  $tablet: 768px;
  $desktop: 1280px;
  $large-desk: 1920px;

  @if $type == 'mob' {
    @media screen and (min-width: $mobile) {
      @content;
    }
  }

  @if $type == 'mobile' {
    @media screen and (max-width: $mobile) {
      @content;
    }
  }

  @if $type == 'mob-only' {
    @media screen and (max-width: ($tablet - 0.02)) {
      @content;
    }
  }

  @if $type == 'tab' {
    @media screen and (min-width: $tablet) {
      @content;
    }
  }

  @if $type == 'tab-only' {
    @media screen and (min-width: $tablet) and (max-width: ($desktop - 0.02)) {
      @content;
    }
  }

  @if $type == 'desk' {
    @media screen and (min-width: $desktop) {
      @content;
    }
  }

  @if $type == 'not-desk' {
    @media screen and (max-width: ($desktop - 0.02)) {
      @content;
    }
  }

  @if $type == 'large-desk' {
    @media screen and (min-width: $large-desk) {
      @content;
    }
  }

  @if $type == 'retina' {
    @media (min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      @content;
    }
  }
}

@mixin aspect-ratio($ratio) {
  aspect-ratio: $ratio;

  @supports not (aspect-ratio: 1 / 1) {
    $padding: calc((1 / $ratio) * 100%);
    position: relative;
    width: 100%;
    padding-top: $padding;

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
