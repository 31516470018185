@import '../../../styles/index.scss';

div .buttonsWrapper {
  display: flex;
  justify-content: end;
  gap: 8px;

  & button {
    width: 179px;
    height: 40px;

    font-family: Inter;
    font-size: 16px;
    font-weight: 700;

    border-radius: 10px;
    text-transform: capitalize;
  }
}

div .cancelButton {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

div .createButton {
  color: var(--neutral-white);
  background-color: var(--primary-color);
}

div .form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

div .inputsWrapper {
  display: flex;
  gap: 24px;
}

div .inputWrapper {
  width: calc((100% - 24px) / 2);
}

div .label {
  margin-bottom: 5px;

  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

div .input > div {
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--border-color);

  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  color: var(--neutral-black);
}
