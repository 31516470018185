.tableHead {
  background-color: #f5f5f5;
}

.tableContainer {
  width: 100%;
  overflow: auto;
}

.headers {
  display: flex;
  background-color: rgba(255, 255, 255, 1);
  padding: 10px 0;
}

.headerCell {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
  padding: 0 20px;
  color: rgba(0, 16, 49, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 23.8px;
  text-align: left;

  &:first-child {
    flex: 0 0 40px;
    justify-content: center;
  }

  &:not(:first-child) {
    flex: 1;
  }

  &.sortable {
    background-color: lightblue;
  }

  svg {
    font-size: 1rem;
    color: rgba(0, 16, 49, 1);
    transition: transform 0.2s;
  }
}

.tableBody {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
}

@media (max-width: 768px) {
  .cell {
    padding: 8px;
  }
}
