@import '../../../styles/index.scss';

div .info {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;

  color: var(--neutral-black);
}

div .desc {
  margin-top: 8px;

  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;

  color: var(--neutral-gray);
}

div .buttonsWrapper {
  display: flex;
  justify-content: end;
  gap: 8px;

  margin-top: 32px;

  & button {
    width: 179px;
    height: 40px;

    font-family: Inter;
    font-size: 16px;
    font-weight: 700;

    border-radius: 10px;
    text-transform: capitalize;
  }
}

div .cancelButton {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

div .deleteButton {
  color: var(--neutral-white);
  background-color: var(--primary-color);
}
