@import '../../styles/index.scss';

div .modal {
  padding: 40px 24px 24px;
  border-radius: 15px;
  background: var(--neutral-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  max-height: 90%;
  max-width: calc(100% - 40px);
  overflow-y: auto;

  @include mq(tab) {
    padding: 46px 32px 32px;
    max-width: 900px;
  }

  @include mq(large-desk) {
    padding: 64px 40px 40px;
  }
}

div .title {
  font-size: 22px;
  font-weight: 600;
  color: var(--neutral-black);
  margin-bottom: 8px;

  @include mq(tab) {
    font-size: 20px;
    margin-bottom: 6px;
  }

  @include mq(large-desk) {
    font-size: 28px;
    margin-bottom: 8px;
  }
}

div .text {
  font-size: 18px;
  color: var(--neutral-gray);

  @include mq(mob-only) {
    font-size: 16px;
  }
}

div .openButton {
  width: 3.75rem;
  height: 3.75rem;
  background: #d9d9d9;
}

div .label {
  font-size: 16px;
  font-weight: 500;
  color: var(--neutral-gray-dark);
  margin-bottom: 8px;
  white-space: wrap;
  font-family: var(--Inter-font);
  line-height: 1.7;
  &Required {
    color: var(--danger-color);
  }
}

div .infoMessage {
  max-width: 475px;
  display: flex;
  background-color: var(--info-message-bg-color);
  padding: 6px 10px;
  border-radius: 10px;
}

div .infoMessageText {
  font-weight: 500;
  font-size: 10px;
  color: var(--info-message-color);
}

div .warningIcon {
  height: 16px;
  fill: var(--info-message-color);
  margin-right: 8px;
}

div .box {
  display: flex;
  justify-content: space-between;
  gap: 2.81rem;
}

div .button {
  text-transform: capitalize;
  font-size: 16px;
  width: 100%;
  transform: 0.5s;
  height: 48px;
  border-radius: 10px;
  white-space: nowrap;

  &Close {
    position: absolute;
    top: 30px;
    right: 30px;

    svg {
      color: var(--neutral-black);
    }

    @include mq(mob-only) {
      top: 15px;
      right: 15px;
    }
  }

  &Submit {
    width: 100%;
    height: 48px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
    border-radius: 10px;
    border-width: 2px;

    @include mq(tab) {
      height: 46px;
    }

    @include mq(large-desk) {
      height: 46px;
    }
  }

  &HeadquarterAdd {
    font-family: var(--Inter-font);
    font-size: 16px;
    border-radius: 10px;
    gap: 5px;
    padding: 0;
  }
}

div .count {
  position: absolute;
  bottom: -1.3rem;
  right: 0.5rem;
  color: black;
  font-size: 12px;
  margin-left: 0.5rem;
}

div .errorCount {
  position: absolute;
  bottom: -1.3rem;
  right: 0.5rem;
  color: red;
  font-size: 12px;
  margin-left: 0.5rem;
}

div .customInput {
  div {
    min-height: 48px;
    font-size: 14px;
    @include mq(tab) {
      min-height: 40px;
      font-size: 12px;
    }

    @include mq(large-desk) {
      min-height: 48px;
      font-size: 14px;
    }
  }
  input {
    caret-color: transparent;
    padding: 12.5px 14px;
  }

  fieldset {
    border-radius: 10px;
    border-color: var(--border-color);
  }
}

div .tagsWrapper {
  display: flex;
  flex-wrap: wrap;
  height: 36px !important;
  overflow: auto;
  width: 100%;
}

div .tag {
  height: 100% !important;
  margin-right: 5px;

  span {
    padding: 0 10px;
  }
}

div .blockWrapper {
  border-radius: 0.4375rem;
  border: 1px solid rgba(196, 196, 196, 0.5);
}

div .block {
  position: relative;
  background: #fff;
  height: max-content;
  margin-top: 16px;
  padding-bottom: 1rem;
  height: 250px;

  @include mq(mob-only) {
    height: 180px;
  }
}

div .contentWrapper {
  width: calc(100% - 32px);
  position: relative;
  left: 16px;
  height: 100%;
}

div .navbar {
  display: flex;
  flex-direction: column;
  background: #c4c4c4;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 6px 6px 6px 16px;
}

div .headerBar {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 32px;
  padding: 5px 15px;
  background: #c4c4c4;
}

div .header {
  height: 5.75rem;
  border-radius: 0.2145rem 0.2145rem 0rem 0rem;
  position: relative;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include mq(mob-only) {
    height: 2.8rem;
  }
}

div .pageLogo {
  display: flex;
  align-items: center;
  padding-left: 16px;
  background: #c4c4c4;
  border-right: 1px solid #fff;
}

div .content {
  text-align: center;
  position: absolute;
  top: 2rem;

  @include mq(mob-only) {
    top: 1.5rem;
  }
}

div .logo {
  height: 5.3125rem;
  width: 5.3125rem;
  border-radius: 50%;
  border: 1px solid #fff;
  object-fit: cover;

  @include mq(mob-only) {
    height: 2.155rem;
    width: 2.155rem;
  }
}

div .imgLabel {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div .gradient {
  position: absolute;
  top: 0px;
  left: -1px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.2) 15%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
  width: calc(100% + 2px);
  height: calc(100% - 91px);

  @include mq(mob-only) {
    top: 47.2px;
    height: calc(100% - 46.2px);
  }
}

div .radioInput {
  display: flex;
  align-items: center;
  margin: 5px 0;
  input {
    width: 22px;
    height: 22px;
    padding: 9px;
    cursor: pointer;
    margin-right: 10px;
  }
}

div .input {
  height: 48px;
  width: 100%;

  input {
    &,
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      font-family: var(--Inter-font);
      color: var(--border-color-dark);
      opacity: 1;
      line-height: 1.7;
    }

    & {
      color: var(--neutral-black);
      z-index: 1;
    }
  }

  fieldset {
    border-radius: 10px;
    border-color: var(--border-color);
    background-color: var(--neutral-white);
    z-index: 0;
  }
}

div .select {
  font-size: 14px;
  color: var(--neutral-black);

  input {
    z-index: 1;
    padding: 0 !important;
  }

  fieldset {
    border-radius: 10px;
    border-color: var(--border-color);
    background-color: var(--neutral-white);
    z-index: 0;
  }

  path {
    color: var(--border-color-dark);
  }
}

div .headquarter {
  &Label {
    font-size: 16px;
    font-weight: 600;
    color: var(--neutral-gray);
    display: flex;
    align-items: center;
  }

  &Button {
    margin-left: 4px;
    svg {
      color: var(--primary-color);
    }
  }
}

div .icon {
  color: var(--primary-color);
}

div .load {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--primary-color);
}

div .image {
  height: 155px;
  width: 155px;
  border-radius: 50%;
  object-fit: cover;
  &Background {
    width: 100%;
    height: 10.625rem;
    object-fit: cover;
  }
}

div .defaultBanner {
  @extend .imageBackground;
  background-color: var(--info-color);
}

div .defaultAvatar {
  @extend .image;
  background-color: var(--info-color);
}

div .defaultAvatarText {
  color: var(--primary-color);
}

div .errorMessage,
div .errorMessageImage {
  color: red;
  font-size: 10px;
  margin-left: 0.5rem;
  position: absolute;
}
div .errorMessage {
  bottom: -1.3rem;
}

div .errorMessageImage {
  top: calc(100% + 10px);
  left: 0;

  @include mq(mob-only) {
    text-align: center;
  }
}

div .fileAddButton {
  width: 90px;
  height: 30px;
  border-radius: 6px;
  background-color: #e6efff;
  color: var(--primary-color);
  box-shadow: none;
  text-transform: capitalize;

  &:hover,
  :active {
    background-color: #ccd6e7;
    box-shadow: none;
  }
}

div .contentToolbar {
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 2px;
  margin: 0;

  & div[class*='rdw-option-wrapper'] {
    background: none;
    border: none;
    transition: 0.2s;
    box-shadow: none;

    &:hover {
      box-shadow: none;
      background: var(--neutral-white);
    }

    &:active {
      background: #eee !important;
    }
  }

  & div[class*='rdw-option-active'] {
    background: #ddd !important;
  }

  & div[class*='modal'] {
    background: #f5f5f5;
    border-radius: 5px;
    height: auto;
    max-height: 430px;
  }

  & input[class*='input'] {
    background: #fff;
    color: #000;
    border-radius: 5px;
    padding: 5px;
  }

  & img[class*='image-preview'] {
    max-height: 150px;
  }

  & span[class*='modal-options'] {
    overflow: auto;
  }

  & ul[class*='rdw-dropdown-optionwrapper'] {
    min-width: 30px;
    padding: 5px;
    overflow-y: auto;
    border-radius: 5px;

    & > li {
      border-radius: 5px;
    }
  }

  & button[class*='modal-btn'] {
    background: #808080;
    border-radius: 5px;
    outline: none;
    border: none;
    box-shadow: none;
    transition: 0.2s;

    &:hover {
      background: rgba(128, 128, 128, 0.8);
    }

    &:active {
      background: rgba(128, 128, 128, 0.6);
    }
  }
}

div .contentEditor {
  height: 517px !important;
  padding: 5px 0 0 0;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--Inter-font);
  line-height: 1.7;

  & > div {
    height: fit-content;
  }

  & > div > div > div > div > div > div {
    margin: 0;
  }
}

div .contentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 22px;
  border: 1px solid var(--border-color);
  color: var(--neutral-black);
  background-color: var(--neutral-white);
  border-radius: 10px;
  box-sizing: border-box !important;
  left: 0;
}
