@import '../../styles/index.scss';

div .metric {
  &DataContainer {
    width: 208px;
    height: 103px;
    background-color: var(--neutral-white);
    border-radius: 12px;
    padding: 20px 17px;
    cursor: pointer;
  }

  &Icon {
    border-radius: 50%;
    background-color: rgba(0, 101, 255, 0.05);

    svg {
      margin: auto;
      fill: var(--primary-color);
    }
  }

  &Title {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7;
    font-family: var(--DMSans-font);
    color: var(--neutral-gray-dark);
  }

  &Total {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    font-family: var(--Inter-font);
    color: var(--neutral-black);
  }
}
