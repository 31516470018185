@import '../../styles/index.scss';

div .row {
  td {
    height: 48px;
    color: var(--neutral-black);
    padding: 12px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7;
    font-family: var(--Inter-font);
    color: var(--neutral-black);
    border: none;
  }

  svg {
    color: var(--neutral-gray);
  }

  &:hover,
  &:focus {
    background-color: #fbfbfb;
  }
}

div .buttonMenu {
  svg {
    width: 24px;
    height: 24px;
    color: var(--neutral-black);
  }
}
