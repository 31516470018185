@import '../../../styles/index.scss';

div .form {
  width: calc(100% - 40px);

  padding: 32px 40px;

  border-radius: 16px;
  background-color: white;

  @include mq(tab) {
    width: 665px;
  }
}

div .formTitle {
  margin-bottom: 28px;

  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;

  color: var(--neutral-black);
}

div .inputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

div .label {
  margin-bottom: 12px;

  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

div .input > div {
  height: 48px;
  border-radius: 10px;
  // border: 1px solid var(--border-color);

  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  color: var(--neutral-black);
}

div .formLink {
  display: block;

  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.9;
  text-align: right;
  text-decoration: underline;

  color: var(--neutral-gray-dark);
}

div .button {
  margin-top: 70px;

  height: 60px;
  border-radius: 10px;

  font-family: Inter;
  font-size: 16px;
  font-weight: 700;

  text-transform: capitalize;
}
