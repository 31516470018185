@import '../../../styles/index.scss';

div .modalHeader {
  position: relative;
  padding: 24px 32px;
  border-bottom: 1px solid var(--border-color);
}

div .modalBody {
  overflow-y: auto;
  padding: 32px;
  background: var(--neutral-white);
  position: relative;

  scrollbar-width: 5px;
  scrollbar-color: var(--border-color) var(--neutral-white);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 6px;
  }
}

div .modalTitle {
  font-family: Poppins;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  color: var(--neutral-black);
}

div .btnClose {
  position: absolute;
  padding: 0px;
  top: 24px;
  right: 32px;
  color: var(--neutral-black);
}
