:global(#app) {
  .header {
    display: flex;
    justify-content: space-between;
    margin: 0;
    min-height: 67px;
    background: #fff;
    padding: 0 30px;

    @media only screen and (max-width: 600px) {
      margin: 0;
    }

    &__desktopMenuWrapper {
      display: flex;
      @media (max-width: 900px) {
        display: none;
      }
    }

    &__mobileMenuWrapper {
      display: none;
      @media (max-width: 900px) {
        display: flex;
      }
    }

    &__mobileMenu {
      ul {
        min-width: 200px;
      }

      button {
        padding: 0.5rem;
        margin: 0;
        width: 100%;
        justify-content: start;
        gap: 0.5rem;

        &:not(:last-child) {
          border-bottom: 1px #eee solid;
        }
      }
    }
  }
}
div .headerLogo {
  color: var(--primary-color);
}

div .headerUserIcon {
  width: 40px;
  height: 40px;
  color: var(--primary-color);
  background-color: #e6efff;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.4875;
  font-family: 'Inter';
}

div .outletContainer {
  padding: 30px 32px;
  width: 100%;
  background: var(--background-color);

  h3 {
    font-weight: 600;
    font-size: 22px;
    font-family: var(--Poppins-font);
    background: var(--background-color);
  }
}
