@import '../../styles/index.scss';

div .profileMenu {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  color: var(--neutral-black);

  @include mq(desk) {
    gap: 12px;
    padding: 5px 16px;
  }

  @include mq(large-desk) {
    gap: 16px;
    padding: 8px 16px;
  }

  svg {
    width: 24px;
    height: 24px;
    color: var(--neutral-black);

    @include mq(desk) {
      width: 20px;
      height: 20px;
    }

    @include mq(large-desk) {
      width: 24px;
      height: 24px;
    }
  }
}

div .avatar {
  width: 40px;
  height: 40px;
}

div .userItem {
  margin: 0 10px;
  &:hover {
    color: var(--background-color);
    border-radius: 10px;
    margin: 0 10px;
  }
  &Active {
    background-color: var(--background-color);
    border-radius: 10px;
    margin: 0 10px;
  }
}
div .menuText {
  color: var(--neutral-black);
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.7;

  @include mq(desk) {
    font-size: 14px;
    line-height: 1.9;
  }

  @include mq(large-desk) {
    font-size: 16px;
    line-height: 1.5;
  }
}
