@import '../../styles/index.scss';

div .buttonCreation {
  height: 30px;

  font-weight: 600;
  font-size: 14px;
  font-family: var(--Inter-font);
  border-radius: 6px;
  text-transform: unset;
  box-shadow: none;

  background-color: var(--primary-color);
  color: var(--neutral-white);

  &:hover,
  :active {
    box-shadow: none;
  }
}

div .title {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.5;

  color: var(--neutral-black);
}

div .content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

div .noContentText {
  flex-grow: 1;
  text-align: center;
  align-content: center;
  background-color: var(--neutral-white);
}

div .workspaceWrapper {
  overflow: auto;
  max-width: 100%;
  max-height: 100%;

  scrollbar-width: 5px;
  scrollbar-color: var(--border-color) var(--neutral-white);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 6px;
  }

  @media (min-width: 900px) {
    max-width: calc(100vw - 340px);
  }
  // @include mq(tab) {
  //   max-width: calc(100vw - 340px);
  // }
}
