.row {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 0;
}

.cell {
  flex: 1;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  border: none;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  text-align: left;
  color: rgba(0, 16, 49, 1);
  background-color: transparent; /* Set default background color */

  &:first-child,
  &:nth-child(odd) {
    background-color: rgba(230, 239, 255, 0.35);
  }

  &:first-child {
    flex: 0 0 40px;
    justify-content: center;
    display: flex;
  }

  &:not(:first-child) {
    flex: 1;
  }

  &:last-child {
    border-right: none;
  }
}

.tableContainer {
  width: 100%;
  overflow: auto;
}
.buttonMenu {
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: rgba(0, 16, 49, 1);
}
@media (max-width: 768px) {
  .cell {
    padding: 8px;
  }
}
