@import '../../../styles/index.scss';

div .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  padding: 32px;

  border-radius: 16px;
  background-color: white;
}

div .successTitle {
  margin-bottom: 8px;

  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;

  color: var(--neutral-black);
}

div .successContent {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.9;
  text-align: center;

  color: var(--neutral-gray-dark);
}

div .successLink {
  text-decoration: underline;
  color: var(--primary-color);
}

div .successIcon {
  width: 145px;
  height: 145px;

  color: var(--primary-color);
}

div .form {
  border-radius: 16px;
  background-color: white;
}

div .formTitle {
  margin-bottom: 14px;

  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: var(--neutral-black);
}

div .formDesc {
  margin-bottom: 28px;

  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: var(--neutral-black);
}

div .label {
  margin-bottom: 12px;

  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

div .input > div {
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--border-color);

  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  color: var(--neutral-black);
}

div .buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  & button {
    margin-top: 40px;

    height: 40px;
    width: 180px;
    border-radius: 10px;
  
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
  
    text-transform: capitalize;
  }
}

div .cancelButton {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

div .submitButton {
  color: var(--neutral-white);
  background-color: var(--primary-color);
}