@import '../../styles/index.scss';

div .label {
  font-size: 16px;
  font-weight: 500;
  color: var(--neutral-gray-dark);
  margin-bottom: 4px;
  white-space: wrap;
  font-family: var(--Inter-font);
  line-height: 1.7;
  &Required {
    color: var(--danger-color);
  }
}

div .input {
  height: 48px;
  width: 100%;

  input {
    &,
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      font-family: var(--Inter-font);
      color: var(--border-color-dark);
      opacity: 1;
      line-height: 1.7;
    }

    & {
      color: var(--neutral-black);
      z-index: 1;
    }
  }

  fieldset {
    border-radius: 10px;
    border-color: var(--border-color);
    background-color: var(--neutral-white);
    z-index: 0;
  }
}

div .contentToolbar {
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 2px;
  margin: 0;

  & > div > div {
    background: none;
    border: none;
    transition: 0.2s;

    &:hover {
      box-shadow: none;
      background: var(--neutral-white);
    }
  }
}

div .contentEditor {
  height: 517px !important;
  padding: 5px 0 0 0;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--Inter-font);
  line-height: 1.7;

  & > div {
    height: fit-content;
  }

  & > div > div > div > div > div > div {
    margin: 0;
  }
}

div .contentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 22px;
  border: 1px solid var(--border-color);
  color: var(--neutral-black);
  background-color: var(--neutral-white);
  border-radius: 10px;
  box-sizing: border-box !important;
}

div .select {
  font-size: 14px;
  color: var(--neutral-black);

  input {
    z-index: 1;
    padding: 0 !important;
  }

  fieldset {
    border-radius: 10px;
    border-color: var(--border-color);
    background-color: var(--neutral-white);
    z-index: 0;
  }

  path {
    color: var(--border-color-dark);
  }
}
