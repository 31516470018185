@import '../../styles/index.scss';

div .row {
  th {
    font-size: 14px;
    font-weight: 600;
    color: var(--neutral-black);
    white-space: nowrap;
    background: var(--neutral-white);
    line-height: 1.7;
    font-family: var(--Inter-font);
    padding: 10px 24px;
    border: none;
  }

  svg {
    color: var(--neutral-gray);
  }
}

div .tableContainer {
  height: 100%;

  scrollbar-width: 5px;
  scrollbar-color: var(--border-color) var(--neutral-white);

  tbody > tr:nth-of-type(odd) {
    background-color: rgba(230, 239, 255, 0.35);
  }
  tbody > tr:nth-of-type(even) {
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 6px;
  }
}
