:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  --primary-color: #0065ff;
  --primary-color-dark: #005be4;
  --primary-color-deep: #0146b2;
  --neutral-primary: #f1f5fe;
  --neutral-white: #ffffff;
  --neutral-black: #001031;
  --border-color: #dfe2e6;
  --border-color-dark: #b3b9c4;
  --neutral-gray: #7a8699;
  --neutral-gray-light: #d6dce5;
  --neutral-gray-dark: #5d6b82;
  --background-color: #f5f6f7;
  --success-color: #2f9d6a;
  --success-color-light: #2cdc87;
  --success-color-background: #eaf5f0;
  --success-color-background-hover: #a1f1cd;
  --danger-color: #dc372c;
  --danger-color-light: #fcebea;
  --danger-color-light-hover: #fadbd9;
  --info-color: #f1f5fe;
  --info-border-color: #bcd2fc;
  --info-message-color: #6e9df8;
  --info-message-bg-color: rgba(110, 157, 248, 0.1);

  --aspect-of-crop-image-collection: 1.8;
  --aspect-of-crop-image-page-details: 3;

  --Inter-font: 'Inter', sans-serif;
  --Poppins-font: 'Poppins', sans-serif;
  --DMSans-font: 'DM Sans', sans-serif;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;

    --aspect-of-crop-image-collection: 1.8;
    --aspect-of-crop-image-page-details: 3;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

input {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px #ffffff inset;
    -webkit-text-fill-color: #000000;
  }
}

body {
  padding: 0px !important;
  // color: rgb(var(--foreground-rgb));
  background-color: var(--background-color) !important;
  // background: linear-gradient(
  //     to bottom,
  //     transparent,
  //     rgb(var(--background-end-rgb))
  //   )
  //   rgb(var(--background-start-rgb));
  & header {
    padding: 0px !important;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

p,
// span,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium {
  box-shadow: none;
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }
}

// @media (prefers-color-scheme: dark) {
//   html {
//     color-scheme: dark;
//   }
// }

// NOTIFICATION STYLES - Notiflix
// Container
body [id^='NotiflixNotifyWrap'] {
  max-width: calc(100% - 40px);

  @include mq(tab) {
    max-width: 420px;
  }
}

// Box
body [id^='NotiflixNotifyWrap'] .notiflix-notify-global {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  padding: 14px 12px !important;
  box-shadow: 3px 7px 28px 1px #e9e9e9a1;

  // Cross button was created as background-image because
  // Library not support 2 functionality: "auto" and "after click on button" close
  background-repeat: no-repeat !important;
  background-position: calc(100% - 12px) 50% !important;
  background-size: 20px !important;
  background-color: #ffffff !important;

  @include mq(large-desk) {
    padding: 17px !important;

    background-position: calc(100% - 17px) 50% !important;
    background-size: 22px !important;
  }
}

// Text
body [id^='NotiflixNotifyWrap'] > div > span.nx-with-icon {
  position: static;
  flex-grow: 1;

  max-width: calc(100% - 96px);
  width: 100%;
  padding: 0px;

  margin-left: 10px;
  margin-right: 30px;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;

  @include mq(large-desk) {
    margin-left: 16px;
    margin-right: 36px;
    font-size: 16px;

    max-width: calc(100% - 122px);
  }
}

// Failure icon
body
  [id^='NotiflixNotifyWrap']
  > div.notiflix-notify-failure
  > .nx-message-icon {
  position: static;
  margin: 0px;

  width: 32px;
  height: 32px;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 22px;

  background-color: #dc372c;
  border-radius: 50%;

  @include mq(large-desk) {
    width: 36px;
    height: 36px;

    background-size: 24px;
  }
}

// Success icon
body
  [id^='NotiflixNotifyWrap']
  > div.notiflix-notify-success
  > .nx-message-icon {
  position: static;
  margin: 0px;

  width: 32px;
  height: 32px;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 22px;

  background-color: #2f9d6a;
  border-radius: 50%;

  @include mq(large-desk) {
    width: 36px;
    height: 36px;

    background-size: 24px;
  }
}
