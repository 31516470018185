.container {
  margin: 0 auto;
  max-width: var(--mobile);
  padding: 0 20px;

  @include mq(tab) {
    max-width: var(--tablet);
    padding: 0 32px;
  }

  @include mq(desk) {
    max-width: var(--desktop);
    padding: 0 16px;
  }
}