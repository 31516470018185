@import '../../styles/index.scss';

div .pagination {
  ul {
    flex-wrap: nowrap;
  }

  button {
    font-size: 16px;
    font-weight: 400;
    color: #1e2433;
  }
  button[aria-current='true'] {
    font-size: 16px;
    font-weight: 900;
    color: #1e2433;
    background-color: var(--neutral-primary);
  }
}
