@import '../../../styles/index.scss';

.formWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  background-color: var(--background-color);
}
