@import '../../styles/index.scss';

div .postCardMenuItem {
  align-items: center;
  gap: 8px;
  padding: 5px 12px;
  color: var(--neutral-black);

  svg {
    width: 22px;
    height: 22px;
    color: var(--neutral-black);

    @include mq(desk) {
      width: 20px;
      height: 20px;
    }

    @include mq(large-desk) {
      width: 22px;
      height: 22px;
    }
  }
}

div .postCardMenuText {
  color: var(--neutral-black);
  font-family: var(--Inter-font);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;

  @include mq(large-desk) {
    font-size: 16px;
    line-height: 1.5;
  }
}
