@import '../../../styles/index.scss';

div .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 750px;
  max-height: 90vh;

  padding: 30px;
  border-radius: 15px;
  background: var(--neutral-white);

  overflow-y: auto;

  @include mq(mob-only) {
    padding: 40px 18px 30px;
    width: calc(100% - 32px);
    max-width: none;
  }

  &Title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    font-family: var(--Poppins-font);
    color: var(--neutral-black);
    text-align: center;
    margin-bottom: 8px;
  }

  &Desc {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.45;
    font-family: var(--Inter-font);
    color: var(--neutral-black);
  }
}

div .button {
  width: 100%;
  height: 46px;
  border-radius: 10px;
  margin-top: 40px;
  font-family: var(--Inter-font);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  text-transform: capitalize;
  color: var(--neutral-white);

  &Cancel {
    color: #1976d2;
  }

  &Close {
    position: absolute;
    top: 13px;
    right: 13px;
    width: 24px;
    height: 24px;
    padding: 3px;
    box-sizing: content-box;
    svg {
      fill: var(--neutral-black);
    }
  }
}

div .cropperWrapper {
  display: block;
  position: relative;

  width: 100%;
  height: 350px;
  min-height: 350px;
  margin-top: 10px;
}
