@import '../../styles/index.scss';

.nav {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  position: relative;
  border-top: 2px solid #f5f6f7;

  div .active {
    p,
    h6,
    path,
    circle {
      color: var(--primary-color);
      font-weight: 600;
    }

    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   height: 100%;
    //   width: 4px;
    //   background-color: var(--primary-color);
    //   border-top-right-radius: 2px;
    //   border-bottom-right-radius: 2px;
    // }
  }

  div .activeChildren {
    @extend .active;
    &:before {
      display: none;
    }
  }

  //Important related to not finding a way to override the icon style
  .icon {
    svg {
      color: var(--neutral-black);
    }
  }

  .text {
    font-weight: 600;
    color: var(--neutral-black);

    line-height: 1.3;
    font-family: 'Poppins';
    &Sub {
      font-weight: 500;
      font-family: 'Inter';
      line-height: 1.7;
    }
  }

  &__sub {
    .text {
      font-weight: 400;
    }
  }
}

.arrow {
  transition: transform 0.3s ease-in-out;

  &.expanded {
    transform: rotate(180deg);
  }
}

div .stat {
  background-color: var(--primary-color);
  font-size: 11.5px;
  font-weight: 600;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  color: white !important;
  text-align: center;
  align-self: center;
  line-height: 19px;

  // @include mq(tab) {
  //   font-size: 12px;
  //   width: 21px;
  //   height: 21px;
  //   line-height: 21px;
  // }
}

div .logo {
  align-items: center;
  height: 65px;
  margin-left: 33px;

  color: var(--primary-color);
}
