@import '../../styles/index.scss';

div .arrow {
  &Button {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: auto 12px auto 0;
  }

  &Icon {
    width: 16px;
    height: 16px;
    fill: var(--neutral-black);
  }
}

div .title {
  color: var(--neutral-black);
  font-size: 22px;
  font-weight: 600;
  font-family: var(--Poppins-font);
}

div .button {
  width: 90px;
  height: 30px;
  border-radius: 6px;
  background-color: var(--primary-color);
  color: var(--neutral-white);
  box-shadow: none;
  text-transform: capitalize;

  &:hover,
  :active {
    background-color: #235fc6;
    box-shadow: none;
  }
}

div .content {
  color: #243757;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--Inter-font);
}
