@import '../../styles/index.scss';

div .post {
  &Container {
    position: relative;
    border-radius: 12px;
    padding: 27px 24px 10px;
    background-color: var(--neutral-white);

    width: 100%;

    @include mq(tab) {
      width: calc((100% - 24px) / 2);
    }

    @include mq(desk) {
      width: calc((100% - 48px) / 3);
    }
  }

  &DetailsContainer {
    position: relative;
    border-radius: 12px;
    padding: 27px 24px 10px;
    background-color: var(--neutral-white);
  }

  &Image {
    display: block;

    border-radius: 8px;
    width: 100%;

    @include aspect-ratio(var(--aspect-of-crop-image-collection));

    object-fit: cover;
    overflow: hidden;
  }

  &ImageDetails {
    @include aspect-ratio(var(--aspect-of-crop-image-collection));
    display: block;

    border-radius: 8px;
    width: 288px;

    object-fit: cover;
    overflow: hidden;
  }

  &ImageDetailsView {
    @include aspect-ratio(var(--aspect-of-crop-image-page-details));
    display: block;
    border-radius: 8px;
    width: 400px;

    object-fit: cover;
    overflow: hidden;
  }

  &LabelContainer {
    position: absolute;
    bottom: 9px;
    left: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 4px 17px;
    border-radius: 100px;
    width: fit-content;
  }
  &LabelText {
    font-family: var(--Inter-font);
    color: var(--neutral-white);
    font-size: 12px;
    font-weight: 500;
  }
  &Type {
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    font-family: var(--Inter-font);
    color: var(--primary-color);
    margin-bottom: 4px;
  }
  &Title {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    font-family: var(--Inter-font);
    color: var(--neutral-black);
    margin-top: 10px;
    margin-bottom: 15px;
    // cursor: pointer;
    // transition: color 0.3s ease;
    // &Hover {
    //   &:hover {
    //     color: var(--primary-color);
    //   }
    // }
  }
  &CreatedBy {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    font-family: var(--Inter-font);
    color: #42526d;
    margin-bottom: 4px;
  }
  &UpdatedAt {
    font-weight: 500;
    font-size: 10px;
    line-height: 1.4;
    font-family: var(--Inter-font);
    color: var(--neutral-gray-dark);
  }
  &ActiveLabel {
    position: absolute;
    top: 37px;
    right: 34px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.4;
    font-family: var(--Inter-font);
    color: var(--primary-color);
    border: 1px solid #e2e2e2;
    border-radius: 100px;
    padding: 2px 12px;
    background: #fff;
  }
  &MoreButton {
    position: absolute;
    top: 9px;
    right: 4px;
    width: 18px;
    height: 18px;
    fill: var(--neutral-black);
    padding: 2px 0px;
    box-sizing: content-box;
  }
}

div .imagesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

div .imageViewDesc {
  margin-bottom: 10px;

  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  font-family: var(--Inter-font);

  color: var(--neutral-black);
}
