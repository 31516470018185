@import '../../../styles/index.scss';

div .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;

  width: calc(100% - 40px);

  padding: 80px 40px;

  border-radius: 16px;
  background-color: white;

  @include mq(tab) {
    width: 665px;
  }
}

div .successTitle {
  margin-bottom: 8px;

  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;

  color: var(--neutral-black);
}

div .successContent {
  width: 359px;

  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.9;
  text-align: center;

  color: var(--neutral-gray-dark);
}

div .successIcon {
  width: 145px;
  height: 145px;

  color: var(--primary-color);
}

div .form {
  width: calc(100% - 40px);

  padding: 32px 40px;

  border-radius: 16px;
  background-color: white;

  @include mq(tab) {
    width: 665px;
  }
}

div .formTitle {
  margin-bottom: 28px;

  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: var(--neutral-black);
}

div .label {
  margin-bottom: 12px;

  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

div .input > div {
  height: 48px;
  border-radius: 10px;
  border: 1px solid var(--border-color);

  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  color: var(--neutral-black);
}

div .formInfo {
  margin-top: 12px;

  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;

  color: var(--neutral-gray-dark);
}

div .button {
  margin-top: 120px;

  height: 60px;
  border-radius: 10px;

  font-family: Inter;
  font-size: 16px;
  font-weight: 700;

  text-transform: capitalize;
}

div .formLink {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  margin-top: 46px;
}

div .formLinkDesc {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;

  color: var(--neutral-black);
}
