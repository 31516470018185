:global(#app) {
  .posts {
    &__header {
      border: 1px solid rgba(30, 76, 161, 0.486);
      background: rgba(195, 222, 253, 0.479);

      &__rightButton {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__body {
      margin: 4rem 7rem 4rem 3rem;

      @media screen and (max-width: 900px) {
        margin: 4rem 3rem 4rem 3rem;
      }

      @media screen and (max-width: 450px) {
        margin: 0;
      }

      &__block {
        border: 1px solid rgba(30, 76, 161, 0.486);
        background: rgba(195, 222, 253, 0.479);
        margin: 2rem;
        padding: 2rem;

        &Right {
          border: 1px solid rgba(30, 76, 161, 0.486);
          background: rgba(195, 222, 253, 0.479);
          margin: 2rem;
          padding: 2rem 3rem;

          @media screen and (max-width: 900px) {
            width: auto;
          }

          @media screen and (max-width: 450px) {
            padding: 2rem;
          }
        }
      }
    }
  }
}

div .post {
  &Container {
    position: relative;
    border-radius: 12px;
    width: 250px;
    height: 260px;
    padding: 27px 24px;
    background-color: var(--neutral-white);
  }
  &Image {
    display: block;
    border-radius: 8px;
    width: 100%;
    height: 152px;
    background: #e7e7e7;
    margin-bottom: 14px;
  }
  &Title {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    font-family: var(--Inter-font);
    color: var(--neutral-black);
  }
  &ActiveLabel {
    position: absolute;
    top: 37px;
    right: 34px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.4;
    font-family: var(--Inter-font);
    color: var(--primary-color);
    border: 1px solid #e2e2e2;
    border-radius: 100px;
    padding: 2px 12px;
    background: #fff;
  }
  &MoreButton {
    position: absolute;
    top: 11px;
    right: 4px;
    width: 18px;
    height: 18px;
    fill: var(--neutral-black);
  }
}

div .addPostButton {
  border-radius: 6px;
  height: 30px;
  width: 102px;
  padding: 6.5px 20px;
  text-transform: unset;
  background-color: var(--primary-color);
  box-shadow: none;

  &:hover,
  :active {
    box-shadow: none;
  }
}

div .buttonCategory {
  border-radius: 6px;
  height: 30px;
  text-transform: unset;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  font-family: var(--Inter-font);

  background-color: #e6efff;
  color: var(--primary-color);
  margin-right: 10px;

  &:hover,
  :active {
    box-shadow: none;
  }
}
