@import '../../styles/index.scss';

div .button {
  border-radius: 6px;
  height: 30px;
  text-transform: unset;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  font-family: var(--Inter-font);

  &Category {
    width: 132px;
    background-color: #e6efff;
    color: var(--primary-color);
    margin-right: 10px;
  }
  &Creation {
    width: 107px;
    background-color: var(--primary-color);
    color: var(--neutral-white);
  }

  &:hover,
  :active {
    box-shadow: none;
  }
}

div .content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

div .tabWrapper {
  padding: 0;
  flex-grow: 1;
  max-width: calc(100vw - 32px);

  @include mq(desk) {
    max-height: calc(100vh - 366px);
  }

  @include mq(large-desk) {
    max-height: calc(100vh - 412px);
  }

  scrollbar-width: 5px;
  scrollbar-color: var(--border-color) var(--neutral-white);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 6px;
  }
}

div .tabListWrapper {
  margin-bottom: 0px;
  align-items: center;

  span {
    font-size: 16px;
    font-weight: 500;
    font-family: var(--Inter-font);
    line-height: 1.5;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: var(--neutral-gray);
    }
  }
}

div .workspaceWrapper {
  flex-grow: 1;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;

  scrollbar-width: 5px;
  scrollbar-color: var(--border-color) var(--neutral-white);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 6px;
  }
}

.MuiTab-root {
  padding: 14px 12px;
  min-height: 52px;
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
}

.MuiTab-root.Mui-selected {
  font-weight: 600;
  border-top: 2px solid #0065ff;
}

.MuiTabs-flexContainer {
  align-items: center;
  height: 52px;
}
